@charset "UTF-8";
@font-face {
  font-family: "Poppins-Light";
  src: url("../fonts/poppins/Poppins-Light.eot");
  src: url("../fonts/poppins/Poppins-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins/Poppins-Light.woff") format("woff"), url("../fonts/poppins/Poppins-Light.ttf") format("truetype"), url("../fonts/poppins/Poppins-Light.svg#Poppins-Light") format("svg")
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("../fonts/poppins/Poppins-Regular.eot");
  src: url("../fonts/poppins/Poppins-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins/Poppins-Regular.woff") format("woff"), url("../fonts/poppins/Poppins-Regular.ttf") format("truetype"), url("../fonts/poppins/Poppins-Regular.svg#Poppins-Regular") format("svg")
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("../fonts/poppins/Poppins-Medium.eot");
  src: url("../fonts/poppins/Poppins-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins/Poppins-Medium.woff") format("woff"), url("../fonts/poppins/Poppins-Medium.ttf") format("truetype"), url("../fonts/poppins/Poppins-Medium.svg#Poppins-Medium") format("svg")
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("../fonts/poppins/Poppins-SemiBold.eot");
  src: url("../fonts/poppins/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins/Poppins-SemiBold.woff") format("woff"), url("../fonts/poppins/Poppins-SemiBold.ttf") format("truetype"), url("../fonts/poppins/Poppins-SemiBold.svg#Poppins-SemiBold") format("svg")
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../fonts/poppins/Poppins-Bold.eot");
  src: url("../fonts/poppins/Poppins-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins/Poppins-Bold.woff") format("woff"), url("../fonts/poppins/Poppins-Bold.ttf") format("truetype"), url("../fonts/poppins/Poppins-Bold.svg#Poppins-Bold") format("svg")
}

@font-face {
  font-family: "SourceSansPro-Regular";
  src: url("../fonts/sourcesanspro/SourceSansPro-Regular.eot");
  src: url("../fonts/sourcesanspro/SourceSansPro-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/sourcesanspro/SourceSansPro-Regular.woff") format("woff"), url("../fonts/sourcesanspro/SourceSansPro-Regular.ttf") format("truetype"), url("../fonts/sourcesanspro/SourceSansPro-Regular.svg#SourceSansPro-Regular") format("svg")
}

@font-face {
  font-family: "SourceSansPro-Semibold";
  src: url("../fonts/sourcesanspro/SourceSansPro-Semibold.eot");
  src: url("../fonts/sourcesanspro/SourceSansPro-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/sourcesanspro/SourceSansPro-Semibold.woff") format("woff"), url("../fonts/sourcesanspro/SourceSansPro-Semibold.ttf") format("truetype"), url("../fonts/sourcesanspro/SourceSansPro-Semibold.svg#SourceSansPro-Semibold") format("svg")
}

@font-face {
  font-family: "SourceSansPro-Bold";
  src: url("../fonts/sourcesanspro/SourceSansPro-Bold.eot");
  src: url("../fonts/sourcesanspro/SourceSansPro-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/sourcesanspro/SourceSansPro-Bold.woff") format("woff"), url("../fonts/sourcesanspro/SourceSansPro-Bold.ttf") format("truetype"), url("../fonts/sourcesanspro/SourceSansPro-Bold.svg#SourceSansPro-Bold") format("svg")
}

@font-face {
  font-family: "SourceSansPro-Italic";
  src: url("../fonts/sourcesanspro/SourceSansPro-It.eot");
  src: url("../fonts/sourcesanspro/SourceSansPro-It.eot?#iefix") format("embedded-opentype"), url("../fonts/sourcesanspro/SourceSansPro-It.woff") format("woff"), url("../fonts/sourcesanspro/SourceSansPro-It.ttf") format("truetype"), url("../fonts/sourcesanspro/SourceSansPro-It.svg#SourceSansPro-It") format("svg")
}

@font-face {
  font-family: "SourceSansPro-LightItalic";
  src: url("../fonts/sourcesanspro/SourceSansPro-LightIt.eot");
  src: url("../fonts/sourcesanspro/SourceSansPro-LightIt.eot?#iefix") format("embedded-opentype"), url("../fonts/sourcesanspro/SourceSansPro-LightIt.woff") format("woff"), url("../fonts/sourcesanspro/SourceSansPro-LightIt.ttf") format("truetype"), url("../fonts/sourcesanspro/SourceSansPro-LightIt.svg#SourceSansPro-LightIt") format("svg")
}

.inline-block {
  display: inline-block;
  vertical-align: top
}

.regular {
  font-family: SourceSansPro-Regular
}

.semibold {
  font-family: SourceSansPro-Semibold
}

.bold {
  font-family: SourceSansPro-Bold
}

.italic {
  font-family: SourceSansPro-Italic
}

.lightitalic {
  font-family: SourceSansPro-LightItalic
}

.light-p {
  font-family: Poppins-Light
}

.regular-p {
  font-family: Poppins-Regular
}

.medium-p {
  font-family: Poppins-Medium
}

.semibold-p {
  font-family: Poppins-SemiBold
}

.bold-p {
  font-family: Poppins-Bold
}

.pseudo {
  content: "";
  position: absolute;
  display: block
}

@font-face {
  font-family: "treneoicons";
  src: url("../fonts/treneoicons/treneoicons.eot");
  src: url("../fonts/treneoicons/treneoicons.eot?#iefix") format("embedded-opentype"), url("../fonts/treneoicons/treneoicons.woff") format("woff"), url("../fonts/treneoicons/treneoicons.ttf") format("truetype"), url("../fonts/treneoicons/treneoicons.svg#treneoicons") format("svg");
  font-weight: normal;
  font-style: normal
}

[data-icon]:before {
  font-family: "treneoicons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "treneoicons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.icon {
  vertical-align: middle;
  display: inline-block
}

.icon-arrowleft:before {
  content: "\66"
}

.icon-arrowright:before {
  content: "\67"
}

.icon-articles:before {
  content: "\69"
}

.icon-back:before {
  content: "\6a"
}

.icon-basket:before {
  content: "\6b"
}

.icon-book:before {
  content: "\6c"
}

.icon-calendar:before {
  content: "\6d"
}

.icon-checked:before {
  content: "\6e"
}

.icon-library:before {
  content: "\6f"
}

.icon-finances:before {
  content: "\70"
}

.icon-envelope:before {
  content: "\73"
}

.icon-enter:before {
  content: "\74"
}

.icon-edit:before {
  content: "\75"
}

.icon-dropdown:before {
  content: "\76"
}

.icon-diary:before {
  content: "\77"
}

.icon-delete:before {
  content: "\78"
}

.icon-dashboard:before {
  content: "\79"
}

.icon-cursor:before {
  content: "\7a"
}

.icon-close:before {
  content: "\41"
}

.icon-link:before {
  content: "\42"
}

.icon-location:before {
  content: "\43"
}

.icon-logout:before {
  content: "\44"
}

.icon-measurement:before {
  content: "\45"
}

.icon-messenger:before {
  content: "\46"
}

.icon-more:before {
  content: "\47"
}

.icon-myacademy:before {
  content: "\48"
}

.icon-phone:before {
  content: "\49"
}

.icon-play:before {
  content: "\4a"
}

.icon-print:before {
  content: "\4b"
}

.icon-search:before {
  content: "\4c"
}

.icon-settings:before {
  content: "\4d"
}

.icon-star:before {
  content: "\4e"
}

.icon-videos:before {
  content: "\4f"
}

.icon-video:before {
  content: "\50"
}

.icon-trainings:before {
  content: "\51"
}

.icon-trainers:before {
  content: "\52"
}

.icon-timer2:before {
  content: "\53"
}

.icon-timer:before {
  content: "\54"
}

.icon-statistics:before {
  content: "\55"
}

.icon-user:before {
  content: "\56"
}

.icon-warning:before {
  content: "\61"
}

.icon-arrowdown:before {
  content: "\65"
}

.icon-arrowup:before {
  content: "\68"
}

.icon-add:before {
  content: "\62"
}

.icon-activity:before {
  content: "\63"
}

.icon-academy:before {
  content: "\64"
}

.icon-error:before {
  content: "\72"
}

.icon-file:before {
  content: "\71"
}

.icon-eye:before {
  content: "\57"
}

.icon-bulb:before {
  content: "\58"
}

.icon-image:before {
  content: "\59"
}

.icon-copy:before {
  content: "\5a"
}

.icon-placeholder:before {
  content: "\30"
}

body {
  height: auto;
  min-height: 1px;
  padding: 0;
  display: block;
}

main {
  overflow: visible !important;
  display: block
}

html * {
  display: block
}

head {
  visibility: hidden
}

script {
  display: none
}

.sidebar-offcanvas {
  display: none
}

.col-offset-200 {
  padding: 0;
  width: 100%;
  position: static;
  display: block
}

.btn {
  display: none
}

.btn-plain {
  display: none !important
}

.main-headline {
  margin: 0 0 20px 0;
  padding: 0
}

.panel-library {
  padding: 0
}

.video, .video-full {
  display: none
}

.training .panel-heading {
  padding: 0
}

.training .panel-body {
  display: none
}

.training .panel-body .section .in .exercise .number {
  background-color: #e5e8eb !important;
  -webkit-print-color-adjust: exact
}

.training .panel-body .section .in .rest {
  background-color: #e5e8eb !important;
  -webkit-print-color-adjust: exact
}

.training .panel-body .section .in .linked-exercises .linked-container .number {
  height: 70px !important;
  line-height: 70px !important;
  background-color: #e5e8eb !important;
  -webkit-print-color-adjust: exact
}

.training .panel-body .section .in .linked-exercises .linked-info .linked-exercise {
  margin: 0 0 38px 0;
  height: 70px
}

.modals-print {
  padding-top: 100px;
  display: none;
}

.modals-print .modal {
  display: block;
  position: relative;
  opacity: 1;
  overflow: visible
}

.modals-print .modal .modal-dialog {
  width: 100%
}

.modals-print .modal .modal-dialog .modal-header .close {
  display: none
}

.modals-print .modal .modal-dialog .modal-body .embed-responsive {
  display: none
}

.modals-print .modal .modal-dialog .modal-body img {
  display: none
}

.modals-print .modal .modal-dialog .modal-body .image-wrapper {
  display: none
}

.modals-print .modal .modal-dialog .modal-body .image-wrapper img {
  display: none
}

.create-training .form-control {
  margin-bottom: 15px;
  width: 100%
}

.create-training .fstElement {
  width: 100%;
  margin: 0 0 15px 0
}

.create-training .single-line {
  text-align: left
}

.video-print {
  display: block;
  margin-bottom: 30px
}

.video-print img {
  width: 100%
}

.training-print {
  display: block
}

.training-print h2 {
  margin-bottom: 8px;
  font-family: SourceSansPro-Bold;
  font-size: 17px;
  text-transform: uppercase;
  color: #3f4143
}

.training-print .exercise {
  margin-bottom: 20px
}

.training-print .exercise .image-wrapper {
  width: 290px;
  height: 160px;
  overflow: hidden;
  display: inline-block;
  vertical-align: top
}

.training-print .exercise .image-wrapper img {
  width: 100%
}

.training-print .exercise .exercise-detail {
  display: inline-block;
  vertical-align: top;
  width: 300px
}

.training-print .exercise .exercise-detail .number {
  display: inline-block;
  vertical-align: top;
  font-family: Poppins-SemiBold;
  font-size: 14px;
  width: 38px;
  height: 38px;
  text-align: center;
  background-color: #e5e8eb !important;
  padding-top: 10px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  margin-left: 8px;
  -webkit-print-color-adjust: exact
}

.training-print .exercise .exercise-detail .info {
  display: inline-block;
  vertical-align: top;
  width: 370px;
  margin-left: 8px
}

.training-print .exercise .exercise-detail .info h3 {
  font-family: SourceSansPro-Bold;
  font-size: 17px;
  margin: 6px 0 7px 0
}

.training-print .exercise .exercise-detail .info p {
  margin-bottom: 0
}

.training-print .rest {
  background-color: #e5e8eb !important;
  -webkit-print-color-adjust: exact;
  margin-bottom: 20px;
  padding: 8px 0 6px 6px
}

.training-print .rest .icon {
  color: #9c9ea0;
  font-size: 26px;
  line-height: 0
}

.training-print .rest p {
  display: inline-block;
  vertical-align: top;
  font-family: SourceSansPro-Semibold;
  font-size: 14px;
  padding: 1px 0 0 25px;
  margin: 0
}

head {
  display: none !important;
}
